import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container, Button } from "react-bootstrap";
import { useQuery } from "react-apollo";
import { GET_ABOUT_ENTRIES } from "../../graphql/AboutEntry";
import { Helmet } from "react-helmet";

import LoadingView from "../../components/app/LoadingView";
import styles from "./PressPage.module.scss";
import Press from "../../components/press/Press";
import ArticleCard from "../../components/articles/ArticleCard";
import ArticleButton from "../../components/articles/ArticleButton";
import ArticleMobile from "../../components/articles/ArticleMobile";
import Social from "../../components/social/Social";
import SocialMobile from "../../components/social/SocialMobile";

import { getMetaText, getCopyText } from "../../AppText";

import heroImg from "../../assets/images/andre-carvalho-k_mLEqWmZug-unsplash.png";
import arrowDown from "../../assets/images/arrow-down.png";
import LoadingLogo from "../../components/app/LoadingLogo";

const PressPage = () => {
  const { data, loading } = useQuery(GET_ABOUT_ENTRIES, {
    variables: {
      entryType: "press_entry",
    },
  });

  const [currentPress, setCurrentPress] = useState<any>([]);
  const [months, setMonths] = useState<any>([]);
  const [monthsMobile, setMonthsMobile] = useState<any>([]);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 995;

  useEffect(() => {
    if (!data?.aboutEntries) {
      return;
    }

    const allMonths = [
      -1,
      ...Array.from(
        new Set(
          data.aboutEntries
            .map((item: any) => new Date(item.publishedDate).getMonth() + 1)
            .sort((a: any, b: any) => b - a),
        ),
      ),
    ];
    const allMonthsMobile = [
      ...Array.from(
        new Set(
          data.aboutEntries
            .map((item: any) => new Date(item.publishedDate).getMonth() + 1)
            .sort((a: any, b: any) => b - a),
        ),
      ),
    ];

    setMonths(allMonths);
    setMonthsMobile(allMonthsMobile);

    filter(-1);
  }, [data?.aboutEntries]);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const filter = (month: number) => {
    if (!data?.aboutEntries) {
      return;
    }
    if (month === -1) {
      setCurrentPress(data.aboutEntries);
      return;
    }
    const filteredData = data.aboutEntries.filter(
      (item: any) => new Date(item.publishedDate).getMonth() + 1 === month,
    );

    setCurrentPress(filteredData);
  };

  if (loading) {
    return <LoadingLogo />;
  }
  return (
    <>
      <Helmet>
        <title>{getMetaText("PRESS_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="keywords" content={getMetaText("PRESS_PAGE", "keywords")} />
        <meta
          name="description"
          content={getMetaText("PRESS_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("PRESS_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("PRESS_PAGE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("PRESS_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin + require("../../assets/images/nicol.png")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("PRESS_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("PRESS_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin + require("../../assets/images/nicol.png")
          }
        />
      </Helmet>
      <Container>
        <Row as="section" className={styles.hero}>
          <Row className={styles.heroM}>
            <Col sm={5} className={styles.heroCol}>
              <h3>
                In The <br /> <span className={styles.underline}>Press</span>
              </h3>
              <div className={styles.paragraphWrapper}>
                <p>{getCopyText("PRESS_PAGE", "introduction")}</p>
                <div className={styles.link}>
                  <img src={arrowDown} alt={arrowDown} />
                </div>
              </div>
            </Col>
            <Col sm={7} className={styles.heroImg}>
              <div className={styles.imgWrapper}>
                <img src={heroImg} alt={heroImg} />
              </div>
              <div className={styles.paragraphWrapper}>
                <p>{getCopyText("PRESS_PAGE", "introduction")}</p>
              </div>
            </Col>
          </Row>
          <Row className={styles.heroM}>
            <Col xs={12} className={styles.pressGrid}>
              <Press />
            </Col>
          </Row>
        </Row>

        {width < breakpoint ? (
          <Row as="section" className={styles.articles}>
            <Col xs={12} className={styles.sort}>
              <ArticleMobile
                button={monthsMobile}
                filter={filter}
                menuItem={currentPress}
                data={data.aboutEntries}
              />
            </Col>
          </Row>
        ) : (
          <Row as="section" className={styles.articles}>
            <Col sm={2} className={styles.sort}>
              <ArticleButton
                button={months}
                filter={filter}
                data={data.aboutEntries}
              />
            </Col>
            <Col sm={10} className={styles.cardWrapper}>
              <ArticleCard menuItem={currentPress} />
            </Col>
          </Row>
        )}

        <Row as="section" className={styles.social}>
          <Col xs={12} className={styles.socialWrapper}>
            {width < breakpoint ? <SocialMobile /> : <Social />}
          </Col>
        </Row>

        <Row as="section" className={styles.caskWrapper}>
          <Col xs={12} className={styles.caskHeading}>
            <h3>
              The Uncompromised Experience of single cask whisky, by the bottle.
            </h3>
          </Col>
          <Col xs={12} className={styles.caskButton}>
            <Button as={Link} to="/caskshares" className={styles.btnPrimary}>
              Browse Caskshares
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PressPage;
