import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FiChevronRight } from "react-icons/fi";
import { useParams, Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";

import { increaseCheckoutQuantity } from "../../../state/slices/checkoutSlice";
import { formatCurrency } from "../../../utils/currency";
import { RootState } from "../../../state/rootReducer";
import { Cask } from "../../../graphql/Casks";
import styles from "./CasksharePage.module.scss";
import ShippingDate from "./ShippingDate";
import { feature } from "../../../utils/featureFlag";

const AddToBasket = ({ cask }: { cask: Cask }) => {
  // @ts-ignore
  const { caskId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const shopId = useSelector((state: RootState) => state.shop.id);
  const currency = useSelector((state: RootState) => state.shop.currency);
  const checkout = useSelector((state: RootState) => state.checkout);
  const loggedInUser = useSelector((state: RootState) => state.user.email);
  const shop = useSelector((state: RootState) => state.shop);
  const collectiveActive = useSelector(
    (state: RootState) => state.user.collectiveActive,
  );

  const [price, setPrice] = useState(0);
  const [collectiveUser, setCollectiveUser] = useState(false);

  useEffect(() => {
    if (cask && !collectiveActive) {
      setPrice(cask?.defaultVariant?.price?.amount);
      setCollectiveUser(false);
    } else if (loggedInUser && cask && collectiveActive) {
      setPrice(cask?.memberPrice);
      setCollectiveUser(true);
    }
    return () => {
      setPrice(0);
      setCollectiveUser(false);
    };
  }, [loggedInUser, cask, collectiveActive]);

  useEffect(() => {
    if (cask && cask?.product && !collectiveActive) {
      setPrice(cask?.product?.defaultVariant?.price?.amount);
    } else if (cask && cask?.product && collectiveActive && loggedInUser) {
      setPrice(cask?.product?.defaultVariant?.memberPrice);
    }
    return () => {
      setPrice(0);
      setCollectiveUser(false);
    };
  }, [loggedInUser, cask?.product, cask, collectiveActive]);

  const reserveCaskshare = () => {
    dispatch(increaseCheckoutQuantity({ product: cask }));
    history.push("/basket");
  };

  const handleOpenNewsLetter = () => {
    // @ts-ignore
    window._klOnsite = window._klOnsite || [];
    // @ts-ignore
    window._klOnsite.push(["openForm", "WT2Cnt"]);
    window.addEventListener("klaviyoForms", function (e: any) {
      if (e.detail.type == "open") {
        // @ts-ignore
        _learnq.push([
          "identify",
          {
            Market: shop.market,
            Cask: !cask.product ? cask.title : cask.product.title,
          },
        ]);
      }
    });
  };

  // shop link
  const shopLink = () => {
    if (cask.product != null && cask.product.slug) {
      return (
        <Button
          className={styles.btnCoralOutline}
          disabled={price <= 0}
          onClick={() => {
            dispatch(increaseCheckoutQuantity({ product: cask.product }));
            history.push("/basket");
          }}
        >
          Add To Basket
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles.priceWrap}>
      <div className={styles.innerWrap}>
        <h3 className={styles.price}>
          {price &&
            formatCurrency(shop.currency, price > 999 ? 0 : 2).format(price)}
        </h3>
        <small>/bottle</small>
      </div>
      {cask?.bottlingYear && cask?.bottlingMonth ? (
        <div className={styles.shippingInfo}>
          <ShippingDate cask={cask} />
        </div>
      ) : null}
      <div className={styles.infoRow}>
        { cask.status === "comingsoon" ? (
          // <p>Register your interest to receive updates on availability</p>
          <p></p>
        ) : cask.collectiveOnly && !collectiveActive ? (
          <p>
            This caskshare is exclusive to{" "}
            <Link to="/the-collective">
              The Collective <FiChevronRight />
            </Link>
          </p>
        ) : feature("paywall") === true ? (
          <p>
            Join {" "}
            <Link to="/the-collective">
              The Collective <FiChevronRight />
            </Link>{" "}
            to Checkout.
          </p>
        ) : null }
      </div>

      <div className={styles.buttonRow}>
        <div className={styles.buttonRow}>
          {loggedInUser &&
           feature("paywall") &&
           !collectiveActive ? (
            <Button
              className={styles.btnCoralOutline}
              onClick={() => history.push("/the-collective")}
            >
              Join The Collective
            </Button>
           ) : null}

          {(!cask.product || !cask.product.slug) &&
            cask.collectiveOnly === false &&
            cask.status !== "comingsoon" &&
            !feature("paywall") ? (
            <Button
              className={styles.btnCoralOutline}
              onClick={reserveCaskshare}
              disabled={
                price <= 0 ||
                cask.status === "allocated" ||
                cask.totalBottles <= cask.totalReservedBottles
              }
            >
              {cask.status === "allocated" ||
               cask.totalBottles === cask.totalReservedBottles
                ? "Sold Out"
                : "Add To Basket"}
            </Button>
          ) : null}

          {cask.status === "comingsoon" && !cask.ballotActive ? (
            <Button
              className={styles.btnCoralOutline}
              // as={Link}
              // to={{
              //   pathname: cask.formLink,
              // }}
              // target="_blank"
              onClick={handleOpenNewsLetter}
            >
              On Request
            </Button>
          ) : null}

          {cask.status === "comingsoon" && cask.ballotActive ? (
            <Button
              className={styles.btnCoralOutline}
              onClick={() => history.push(`/ballot/${cask.ballotSlug}`)}
            >
              Buy Now
            </Button>
          ) : null}

          {(cask.collectiveOnly === true || feature("paywall")) &&
           loggedInUser &&
           collectiveActive === true &&
           cask.status !== "comingsoon" &&
           (!cask.product || !cask.product.slug) ? (
            <Button
              className={styles.btnCoralOutline}
              onClick={reserveCaskshare}
              disabled={
                price <= 0 ||
                cask.status === "allocated" ||
                cask.totalBottles <= cask.totalReservedBottles
              }
            >
              {cask.status === "allocated" ||
              cask.totalBottles === cask.totalReservedBottles
                ? "Sold Out"
                : "Add To Basket"}
            </Button>
          ) : null}

          {(cask.collectiveOnly === true &&
            collectiveActive === false &&
            (!cask.product || !cask.product.slug)) ||
           (cask.collectiveOnly === true &&
            !loggedInUser &&
            (!cask.product || !cask.product.slug)) ||
           (!loggedInUser && feature("paywall")) ? (
            <>
              <Button
                className={styles.btnCoralOutline}
                onClick={() => history.push("/login")}
              >
                {cask.shippingTimeframe === "shipping_now"
                  ? "Sign in to buy"
                  : "Sign in to reserve"}
              </Button>
              {/* <a
                className={styles.loginLink}
                onClick={() => {
                  history.push({
                    pathname: "/login",
                    state: {
                      from: {
                        pathname: `/caskshares/${caskId}`,
                      },
                    },
                  });
                }}
              >
                Already a member? Login
              </a> */}
            </>
          ) : null}
          {cask.status === "bottled" &&
          cask.product &&
          cask.product.slug &&
          cask.product?.quantity >= 1
            ? shopLink()
            : null}
          {cask.status === "bottled" &&
          cask.product &&
          cask.product?.quantity <= 0 ? (
            <Button
              className={styles.btnCoralOutline}
              disabled={cask.product?.quantity <= 0}
            >
              Sold Out
            </Button>
          ) : null}
        </div>
      </div>
      {shop.market === 'GB' ? (
        <div className={styles.infoRow}>
          <p className={styles.priceNotice}>
            <small>Buying outside UK? VAT removed at checkout.</small>
          </p>
        </div>
      ) : shop.market === 'US' ? (
        <div className={styles.infoRow}>
          <p className={styles.priceNotice}>
            <small>* Sales taxes included in price.</small>
          </p>
        </div>
      ) : null }
    </div>
  );
};

export default AddToBasket;
