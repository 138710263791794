import { gql } from "apollo-boost";

export type AboutEntry = {
  id: string;
  title: string;
  body: string;
  url: string;
  ordinal: number;
};

export interface AboutEntries {
  aboutEntries: AboutEntry[];
}

export const GET_ABOUT_ENTRIES = gql`
  query aboutEntries($entryType: String!) {
    aboutEntries(entryType: $entryType) {
      id
      title
      body
      bodyTwo
      url
      ordinal
      favouriteFlavour
      cask {
        title
      }
      publishedDate
      attachments {
        url
        title
      }
    }
  }
`;
