import React, { useState } from "react";

import styles from "./ArticleButton.module.scss";

// Number to month
function getMonthName(monthVar: number) {
  const d = new Date();
  d.setMonth(monthVar - 1);
  const monthName = d.toLocaleString("default", { month: "long" });
  return monthName;
}

const ArticleButton = ({
  button,
  filter,
  data,
}: {
  button: any;
  filter: any;
  data: Array<string | number>;
}) => {
  // get all months
  const allMonths = [
    ...data
      .map((item: any) => new Date(item.publishedDate).getMonth() + 1)
      .sort((a: number, b: number) => a - b),
  ];

  // value pairs of each month
  const count = allMonths.reduce((acc: number | any, val) => {
    acc[val] = acc[val] === undefined ? 1 : (acc[val] += 1);
    return acc;
  }, {});

  const [isActive, setActive] = useState<number>(-1);

  const handleActive = (month: number) => {
    setActive(month);
  };
  return (
    <>
      {button.map((month: number, i: number) => {
        return (
          <div
            className={`${styles.dateWrapper} ${
              isActive === month ? styles.active : ""
            }`}
            key={i}
          >
            <p
              onClick={() => {
                filter(month);
                handleActive(month);
              }}
            >
              {month === -1 ? "All" : getMonthName(month) + " 2021"}
            </p>
            <hr className={styles.line}></hr>
            <small>
              {month === -1
                ? "(" + allMonths.length + ")"
                : "(" + count[month] + ")"}
            </small>
          </div>
        );
      })}
    </>
  );
};

export default ArticleButton;
