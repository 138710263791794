import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Container, Form } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { useQuery, useMutation } from "react-apollo";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { hotjar } from "react-hotjar";
import { gsap } from "gsap";

import CountdownTimer from "../../../components/.v2/general/CountdownTimer/CountdownTimer";
import GeneralSliderMobile from "../../../components/general-slider/GeneralSliderMobile";
import GeneralSlider from "../../../components/general-slider/GeneralSlider";
import { BALLOT, BALLOT_CHECKOUT } from "../../../graphql/Ballot";
import { SHOPS, Shop } from "../../../graphql/Shops";
import { setShop } from "../../../state/slices/shopSlice";
import { resetUser } from "../../../state/slices/userSlice";
import { resetCheckout } from "../../../state/slices/checkoutSlice";
import ScrollToSection from "../../../components/app/ScrollToSection";
import { formatCurrency } from "../../../utils/currency";
import { RootState } from "../../../state/rootReducer";
import { IMAGE_FALLBACKS } from "../../../config";
import styles from "./PrivateSale.module.scss";
import { getMetaText, getSpiritStyle } from "../../../AppText";
import LoadingLogo from "../../../components/app/LoadingLogo";
import BottleImage from "../../../caskshare-v3/components/casks/caskCard/BottleImage";
import FlavourComp from "../../../caskshare-v3/components/casks/flavourComponent/FlavourComp";
import caskshareLogo from "../../../assets/images/share-dots-light.png";
import BallotFallback from "./BallotFallback";

const PrivateSale = () => {
  hotjar.initialize(2882523, 6);
  type PrivateSale = {
    attachments: [];
    cask?: {};
    closeAt: string;
    currency?: string;
    countdown: boolean;
    description: string;
    drawAt: string;
    shippingAt: string;
    eur: number;
    gbp: number;
    id: string;
    openAt: string;
    priority: string;
    privateSalesDescription: string;
    product?: {
      image?: string;
    };
    quantityPicker: boolean;
    quantity: number;
    shop: {
      market: string;
    };
    slug: string;
    status: string;
    subtitle: string;
    pageTitle: string;
    terms: string;
    termsUrl: string;
    termsButtonText: string;
    title: string;
    type: string;
    usd: number;
  };

  const PRIVATE_SALE_INIT: PrivateSale = {
    attachments: [],
    cask: {},
    closeAt: "",
    countdown: false,
    currency: "gbp",
    description: "",
    drawAt: "",
    shippingAt: "",
    eur: 0,
    gbp: 0,
    id: "",
    openAt: "",
    priority: "",
    privateSalesDescription: "",
    product: {
      image: "",
    },
    quantityPicker: false,
    quantity: 0,
    shop: {
      market: "",
    },
    slug: "",
    status: "",
    subtitle: "",
    pageTitle: "",
    title: "",
    terms: "",
    termsUrl: "",
    termsButtonText: "",
    type: "",
    usd: 0,
  };
  //@ts-ignore
  const { slug } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const shop = useSelector((state: RootState) => state.shop);
  const user = useSelector((state: RootState) => state.user);
  const descriptionRef: any = useRef<HTMLDivElement>();
  const artistDescRef: any = useRef<HTMLDivElement>();
  const formatDate = (d: Date) =>
    d.toLocaleString("default", { month: "short" }) + " " + d.getFullYear();

  const breakpoint = 992;
  const [scroll, setScroll] = useState("top");
  const [productQTY, setProductQTY] = useState(1);
  const [runRefetch, setRunRefetch] = useState(false);
  const [ballotStatus, setBallotStatus] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [isUrl, setIsUrl] = useState("");
  const [ballotItem, setBallotItem] = useState<any>(PRIVATE_SALE_INIT);
  const [readFullDesc, setReadFullDesc] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const [showMarketModal, setShowMarketModal] = useState(false);
  const [ballotMarket, setBallotMarket] = useState<any>({});
  const [bottleLrgUrl, setBottleLrgUrl] = useState<string>("");
  const [bottleSmUrl, setBottleSmUrl] = useState<string>("");
  const [shadow, setShadow] = useState(false);
  const [ballotInfoData, setBallotInfoData] = useState<any>([
    {
      id: 0,
      title: "release",
      body: "",
    },
    {
      id: 1,
      title: "payment",
      body: "",
    },
    {
      id: 2,
      title: "terms",
      body: "",
      buttonUrl: "",
      buttonName: "",
    },
    {
      id: 3,
      title: "priority",
      body: "",
    },
  ]);

  const [ballotCheckout, { loading: ballotCheckoutLoading }] =
    useMutation(BALLOT_CHECKOUT);

  const { data: shopData, loading: shopLoading } = useQuery(SHOPS);

  const { data, refetch, loading } = useQuery(BALLOT, {
    variables: {
      shop: shop.id,
      slug: slug,
    },
  });

  useEffect(() => {
    if (data?.ballot && !loading) {
      setBallotItem(data.ballot);
    }
    return () => {
      setBallotItem(PRIVATE_SALE_INIT);
    };
  }, [data?.ballot, runRefetch]);

  useEffect(() => {
    if (
      shopData?.shops &&
      ballotItem?.shop?.market &&
      shop.market !== ballotItem?.shop?.market
    ) {
      let ballotStore = shopData.shops.filter(
        (a: any) => a.market === ballotItem?.shop?.market,
      );

      setShowMarketModal(true);
      setBallotMarket(ballotStore[0]);
      document.body.style.overflow = "hidden";
    }
    return () => {
      setShowMarketModal(false);
      setBallotMarket({});
      document.body.style.overflow = "initial";
    };
  }, [shopData?.shops, shop?.market, ballotItem?.shop?.market]);

  useEffect(() => {
    if (runRefetch) {
      refetch();
    }
  }, [runRefetch]);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (ballotItem.status == "Open") {
      const today = new Date();

      let memberWindow = new Date(ballotItem.openAt);
      memberWindow.setDate(memberWindow.getDate() + 1);

      if (today < memberWindow) {
        if (user.email && user.collectiveActive) {
          setBallotStatus(ballotItem.status);
        } else {
          setBallotStatus("Coming Soon");
        }
      } else {
        setBallotStatus(ballotItem.status);
      }
    } else {
      setBallotStatus(ballotItem.status);
    }

    return () => {
      setBallotStatus("");
    };
  }, [ballotItem?.status]);

  // useEffect(() => {
  //   if (ballotItem.status === "Closed" || ballotItem.status === "Drawn") {
  //     history.replace("/ballot");
  //   }
  // }, [ballotItem?.status]);

  useEffect(() => {
    const attachments = ballotItem?.cask?.attachments;
    if (attachments && attachments.length > 0) {
      const getLargeBottle = attachments?.filter(
        (a: any) => a.role === "large",
      )[0]?.url;
      const getSmallBottle = attachments?.filter(
        (a: any) => a.role === "small",
      )[0]?.url;

      setBottleLrgUrl(
        getLargeBottle ?? require("../../../assets/home-page/.v3/db-lg.png"),
      );
      setBottleSmUrl(
        getSmallBottle ?? require("../../../assets/home-page/.v3/db-sm.png"),
      );
    } else {
      setBottleLrgUrl(require("../../../assets/home-page/.v3/db-lg.png"));
    }
  }, [ballotItem?.cask, ballotItem?.cask?.attachments]);

  useEffect(() => {
    if (ballotItem?.product) {
      setBottleLrgUrl(
        ballotItem?.product?.image ?? require("../../../assets/home-page/.v3/db-lg.png"),
      );
    }
  }, [ballotItem?.product, ballotItem?.product?.image]);

  useEffect(() => {
    if (descriptionRef?.current) {
      if (
        // @ts-ignore
        descriptionRef?.current.offsetHeight <
        descriptionRef?.current.scrollHeight
      ) {
        setShowReadMore(true);
      } else {
        setShowReadMore(false);
      }
    }
    if (artistDescRef?.current) {
      if (
        // @ts-ignore
        artistDescRef?.current.offsetHeight <
        artistDescRef?.current.scrollHeight
      ) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    }
    return () => {
      setShowReadMore(false);
      setShadow(false);
    };
  }, [descriptionRef?.current, artistDescRef?.current]);

  useEffect(() => {
    if (history?.location?.pathname.includes("private-sale")) {
      setIsUrl(() => "private-sale");
    } else if (history?.location?.pathname.includes("ballot")) {
      setIsUrl(() => "ballot");
    } else if (history?.location?.pathname.includes("special-release")) {
      setIsUrl(() => "special-release");
    }
    return () => setIsUrl("");
  }, [history?.location?.pathname]);

  useEffect(() => {
    if (data?.ballot?.product && ballotInfoData?.length) {
      const bottle = data?.ballot?.product?.release || "TBA..";
      const paymentDesc = data?.ballot?.paymentSummary || "TBA..";
      const termsDesc = data?.ballot?.terms;
      const termsUrl = data?.ballot?.termsUrl || "/term/terms-and-conditions";
      const termsName = data?.ballot?.termsButtonText || "T&Cs of Sale";
      const priorityDesc = data?.ballot?.priority;

      ballotInfoData[0]["body"] = bottle;
      ballotInfoData[1]["body"] = paymentDesc;
      ballotInfoData[2]["body"] = termsDesc;
      ballotInfoData[2]["buttonUrl"] = termsUrl;
      ballotInfoData[2]["buttonName"] = termsName;
      ballotInfoData[3]["body"] = priorityDesc;
      setBallotInfoData(ballotInfoData);
    }
  }, [data?.ballot?.product]);

  useEffect(() => {
    if (data?.ballot?.cask && ballotInfoData?.length) {
      const caskDesc = data?.ballot?.cask?.description || "TBA..";
      const paymentDesc = data?.ballot?.cask?.release || "TBA..";
      const termsDesc = data?.ballot?.terms;
      const termsUrl = data?.ballot?.termsUrl || "/term/terms-and-conditions";
      const termsName = data?.ballot?.termsButtonText || "T&Cs of Sale";
      const priorityDesc = data?.ballot?.priority;

      // ballotInfoData[0]["body"] = caskDesc;
      ballotInfoData[1]["body"] = paymentDesc;
      ballotInfoData[2]["body"] = termsDesc;
      ballotInfoData[2]["buttonUrl"] = termsUrl;
      ballotInfoData[2]["buttonName"] = termsName;
      ballotInfoData[3]["body"] = priorityDesc;
      setBallotInfoData(ballotInfoData);
    }
  }, [data?.ballot?.cask]);

  const handleEnterBallot = async () => {
    if (!user.email) {
      history.push({
        pathname: "/login",
        state: {
          from: {
            pathname: `/${isUrl}/${slug}`,
          },
        },
      });
    } else {
      const stripe = await loadStripe(shop.stripePublishableKey);
      const { data: ballotData } = await ballotCheckout({
        variables: {
          shop: shop.id,
          quantity: productQTY,
          item: ballotItem?.slug,
          currencyCode: ballotItem.currency || "GBP",
          successPath: `ballot-success/${ballotItem?.slug}`,
          cancelPath: `${isUrl}/${slug}`,
        },
      });

      if (ballotData.ballotCheckout.sessionId && !ballotCheckoutLoading) {
        stripe?.redirectToCheckout({
          sessionId: ballotData.ballotCheckout.sessionId,
        });
      }
    }
  };

  const textSubstitutions = {
    "Private-Sale-name": ballotItem.pageTitle,
    "Private-Sale-description": ballotItem.description,
  };

  const handleOpenNewsLetter = () => {
    // @ts-ignore
    window._klOnsite = window._klOnsite || [];
    // @ts-ignore
    window._klOnsite.push(["openForm", "V9876V"]);
    window.addEventListener("klaviyoForms", function (e: any) {
      if (e.detail.type == "open") {
        // @ts-ignore
        _learnq.push([
          "identify",
          {
            Market: shop.market,
            Cask:
              ballotItem.type === "Cask"
                ? ballotItem?.cask?.title
                : ballotItem?.cask?.product?.title,
          },
        ]);
      }
    });
  };

  if (loading || shopLoading) {
    return <LoadingLogo />;
  }

  // if (!ballotItem?.cask || !data?.ballot) {
  if (!data?.ballot) {
    return <BallotFallback />;
  }

  const getLargeBottle = (attachments: any) => {
    let images = attachments?.filter((a: any) => a.role === "large");
    let image = images?.length
      ? images[0]?.url
      : require("../../../assets/home-page/.v3/db-lg.png");
    return image;
  };
  const getSmallBottle = (attachments: any) => {
    let images = attachments?.filter((a: any) => a.role === "small");
    let image = images?.length
      ? images[0]?.url
      : require("../../../assets/home-page/.v3/db-sm.png");
    return image;
  };
  const getInsert1Image = (attachments: any) => {
    let images = attachments.filter((a: any) => a.role === "img-1");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPagePremises;
    return image;
  };
  const getInsert2Image = (attachments: any) => {
    let images = attachments.filter((a: any) => a.role === "img-2");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPagePremises;
    return image;
  };
  const getInsert3Image = (attachments: any) => {
    let images = attachments.filter((a: any) => a.role === "img-3");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPagePremises;
    return image;
  };
  const getInsert4Image = (attachments: any) => {
    let images = attachments.filter((a: any) => a.role === "img-4");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPagePremises;
    return image;
  };
  const getInsert5Image = (attachments: any) => {
    let images = attachments.filter((a: any) => a.role === "img-5");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPagePremises;
    return image;
  };
  const getInsert6Image = (attachments: any) => {
    let images = attachments.filter((a: any) => a.role === "img-6");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPagePremises;
    return image;
  };
  const getBallotBottleImage = (attachments: any) => {
    let images = attachments.filter((a: any) => a.role === "bottle");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.defaultBottle;
    return image;
  };

  const ballotDescriptionMarkup = (description?: string) => {
    return {
      __html: `
    ${
      description
        ? `<p class="preserve-new-lines">${description}</p>`
        : `<p style="display: none"></p>`
    }
    `,
    };
  };

  const ballotTitleMarkup = (title?: string) => {
    return {
      __html: `
    ${title ? `<h3 style="">${title}</h3>` : `<h3 style="display: none"></h3>`}
    `,
    };
  };

  let bottledDate;
  if (ballotItem?.cask?.bottlingMonth && ballotItem?.cask?.bottlingYear) {
    bottledDate = formatDate(
      new Date(
        ballotItem.cask.bottlingYear,
        ballotItem.cask.bottlingMonth - 1,
        1,
      ),
    );
  } else if (ballotItem.product?.title) {
    bottledDate = "Bottled";
  } else {
    bottledDate = "TBA...";
  }

  const shippingDate = () => {
    let shippingDate;
    if (ballotItem.shippingAt) {
      let shippingAt = new Date(ballotItem.shippingAt);
      shippingDate = new Date(shippingAt.getFullYear(), shippingAt.getMonth()+1, 1);
    } else if (ballotItem?.cask?.bottlingYear && ballotItem?.cask?.bottlingMonth) {
      shippingDate = new Date(ballotItem.cask.bottlingYear, ballotItem.cask.bottlingMonth + 1 + shop.shippingMonthIncrement, 0);
    }
    let today = new Date();

    if (Number(shippingDate) >= Number(today)) {
      return (
        <p>
          { ballotItem?.cask ?
            `Est. shipping:${" "}`
          :
            `Shipping:${" "}`
          }
          <span>
            {shippingDate ? formatDate(shippingDate) : ""}
          </span>
        </p>
      );
    } else if (Number(shippingDate) < Number(today)) {
      return (
        <p>
          <span>Shipping now</span>
        </p>
      );
    } else {
      return "";
    }
  };

  const handleMarketChange = () => {
    dispatch(setShop(ballotMarket));
    dispatch(resetUser());
    dispatch(resetCheckout());
  };

  return (
    <>
      <Helmet>
        <title>
          {getMetaText("PRIVATE_SALE_PAGE", "title", textSubstitutions)}
        </title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="keywords"
          content={getMetaText(
            "PRIVATE_SALE_PAGE",
            "keywords",
            textSubstitutions,
          )}
        />
        <meta
          name="description"
          content={getMetaText(
            "PRIVATE_SALE_PAGE",
            "description",
            textSubstitutions,
          )}
        />
        <meta
          name="author"
          content={getMetaText(
            "PRIVATE_SALE_PAGE",
            "author",
            textSubstitutions,
          )}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("PRIVATE_SALE_PAGE", "title", textSubstitutions)}
        />
        <meta
          property="og:description"
          content={getMetaText(
            "PRIVATE_SALE_PAGE",
            "description",
            textSubstitutions,
          )}
        />
        <meta
          property="og:image"
          content={
            ballotItem?.type === "Cask"
              ? window.location.origin +
                getLargeBottle(ballotItem?.cask?.attachments)
              : ballotItem?.product?.image
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("PRIVATE_SALE_PAGE", "title", textSubstitutions)}
        />
        <meta
          name="twitter:description"
          content={getMetaText(
            "PRIVATE_SALE_PAGE",
            "description",
            textSubstitutions,
          )}
        />
        <meta
          name="twitter:image"
          // content={window.location.origin + ballotItem?.product?.image}
          content={
            ballotItem?.type === "Cask"
              ? window.location.origin +
                getLargeBottle(ballotItem?.cask?.attachments)
              : ballotItem?.product?.image
          }
        />
      </Helmet>
      <ScrollToSection scroll={scroll} setScroll={setScroll} />
      <Container
        fluid
        className={`${styles.privateSalePageWrap} ${
          showMarketModal && styles.blur
        }`}
      >
        <div className={styles.beigeBG}>
          <Container className={`${styles.sectionWrap} ${""}`}>
            <Row className={`${styles.caskDetailRow} ${styles.whiteBG}`}>
              <Col xs={12} md={6} className={styles.imgWrap}>
                {bottleLrgUrl.length /*&& bottleSmUrl.length*/ ? (
                  <BottleImage
                    src={bottleLrgUrl}
                    placeholderSrc={bottleSmUrl}
                    props={{ alt: ballotItem?.cask?.title ?? ballotItem?.product?.title }}
                    hideOverlay={true}
                  />
                ) : null}
              </Col>
              <Col xs={12} md={6} className={styles.contentWrap}>
                <div className={styles.titleWrap}>
                  <div className={styles.distillery}>
                    <p>{ballotItem.subtitle}</p>
                  </div>
                  <div
                    className={styles.title}
                    dangerouslySetInnerHTML={ballotTitleMarkup(
                      ballotItem?.title,
                    )}
                  />
                  <div className={styles.descriptionWrap}>
                    <p
                      ref={descriptionRef}
                      className={`${readFullDesc && styles.fullDescription} ${
                        styles.description
                      }`}
                      dangerouslySetInnerHTML={
                        ballotItem.cask ?
                          ballotDescriptionMarkup(ballotItem?.cask.description)
                        : ballotDescriptionMarkup(ballotItem?.product.description)
                        }
                    />
                    {!readFullDesc && showReadMore ? (
                      <p
                        className={styles.more}
                        onClick={() => setReadFullDesc((prev) => !prev)}
                      >
                        read more
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className={styles.detailWrap}>
                  <div className={styles.caskInfoWrap}>
                    <div className={styles.inner}>
                      <p>Bottling strength</p>
                      {ballotItem?.cask?.isCaskStrength ? (
                        <p className={styles.info}>Cask strength</p>
                      ) : ballotItem?.cask?.bottlingStrength ? (
                        <p className={styles.info}>
                          {ballotItem?.cask?.bottlingStrength}%
                        </p>
                      ) : (
                        <p className={styles.info}>Cask Strength</p>
                      )}
                    </div>
                    <div className={styles.inner}>
                      <p>Sold by</p>
                      <p className={styles.info}>
                        {ballotItem?.cask?.seller?.title ?? ballotItem?.product?.seller?.title}
                      </p>
                    </div>
                    <div className={styles.inner}>
                      <p>Bottling type</p>
                      <p className={styles.info}>Independent bottling</p>
                    </div>
                    <div className={styles.inner}>
                      <p>Spirit style</p>
                      <p className={styles.info}>
                        {ballotItem?.cask?.spiritStyle &&
                        ballotItem?.cask?.spiritStyle !== "plain"
                          ? getSpiritStyle(
                              ballotItem?.cask?.spiritStyle,
                              "text",
                            )
                          : "Multiple"}
                      </p>
                    </div>
                    <div className={styles.inner}>
                      <p>Bottling date</p>
                      <p className={styles.info}>
                        {bottledDate ? bottledDate : "TBA.."}
                      </p>
                    </div>
                    <div className={styles.inner}>
                      <p>
                        {ballotItem?.cask?.totalBottles
                          ? "Est. total yield"
                          : "Quantity"}
                        </p>
                      <p className={styles.info}>
                        {ballotItem?.cask?.totalBottles
                          ? `${ballotItem?.cask?.totalBottles} Bottles`
                          : ballotItem?.quantity}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.priceWrap}>
                  <div className={styles.innerWrap}>
                    <h3 className={styles.price}>
                      {formatCurrency(ballotItem.currency || "gbp", 0).format(
                        ballotItem.currency === "EUR"
                          ? ballotItem.eur
                          : ballotItem.currency === "USD"
                          ? ballotItem.usd
                          : ballotItem.gbp,
                      )}
                    </h3>
                    <small>/ entry</small>
                  </div>

                  <div className={styles.shippingInfo}>{shippingDate()}</div>

                  <div
                    className={styles.descriptionWrap}
                    dangerouslySetInnerHTML={ballotDescriptionMarkup(
                      ballotItem?.privateSalesDescription,
                    )}
                  />

                  <Button
                    className={styles.btnCoralOutline}
                    onClick={() => setScroll("EnterBallot")}
                  >
                    Read more
                  </Button>
                </div>
              </Col>
              <Col className={styles.flavourWrap} xs={12}>
                {ballotItem?.cask ? (
                  <FlavourComp caskData={ballotItem?.cask} />
                ) : (
                  <Row>
                    <Col
                      xs={12}
                      className={styles.productDetails}
                    >
                      <Row>
                        <Col md={1} className={`${styles.logoWrap} d-none d-md-block`}>
                          <img className="img-fluid" src={caskshareLogo} alt="caskshare logo" />
                        </Col>
                        <Col xs={12} sm={11} className={styles.details}>
                          <span
                            dangerouslySetInnerHTML={
                              ballotDescriptionMarkup(ballotItem?.product?.release)
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Container>
        </div>

        {/* Ballot Detail */}

        <div className={`${styles.beigeBG}`}>
          <Container className={styles.sectionWrap}>
            <Row
              id="EnterBallot"
              className={`${styles.distilleryRow} ${styles.whiteBG} ${styles.spacer}`}
            >
              <Col xs={12} md={6} className={styles.imgsWrap}>
                <div id="enjoyImgLeft" className={styles.topImg}>
                  <img
                    src={String(getInsert1Image(ballotItem?.attachments))}
                    alt="caskshare marketing image"
                  />
                </div>
                <div id="enjoyImgRight" className={styles.bottomImg}>
                  <img
                    src={String(getInsert2Image(ballotItem?.attachments))}
                    alt="caskshare marketing image"
                  />
                </div>
              </Col>
              <Col xs={12} md={6} className={styles.contentWrap}>
                <div className={styles.inner}>
                  <h3>special release</h3>
                  <div
                    className={styles.descriptionWrap}
                    dangerouslySetInnerHTML={ballotDescriptionMarkup(
                      ballotItem?.description,
                    )}
                  />
                  {ballotStatus === "Coming Soon" ||
                  ballotStatus === "Open" ? (
                    <div className={styles.selectionGrid}>
                      {ballotItem?.countdown && (
                        <div className={styles.wrap}>
                          {ballotStatus === "Open" ? (
                            <p>Time left</p>
                          ) : ballotStatus === "Coming Soon" ? (
                            <p>Starts in</p>
                          ) : (
                            ""
                          )}
                          <div className={styles.countdown}>
                            {ballotStatus === "Coming Soon" ? (
                              <CountdownTimer
                                setRunRefetch={setRunRefetch}
                                targetDate={new Date(
                                  ballotItem?.openAt,
                                ).getTime()}
                              />
                            ) : (
                              <CountdownTimer
                                setRunRefetch={setRunRefetch}
                                targetDate={new Date(
                                  ballotItem?.closeAt,
                                ).getTime()}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      {ballotStatus === "Open" &&
                      ballotItem?.quantityPicker ? (
                        <div className={styles.wrap}>
                          <p>Quantity</p>
                          <div className={styles.qtyPicker}>
                            <button
                              type="button"
                              disabled={productQTY === 1}
                              onClick={() =>
                                productQTY > 1 &&
                                setProductQTY((prev) => prev - 1)
                              }
                            >
                              -
                            </button>
                            <p className="">{productQTY}</p>
                            <button
                              type="button"
                              disabled={productQTY < 1}
                              onClick={() => setProductQTY((prev) => prev + 1)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      ) : null}
                      {ballotStatus === "Open" && (
                        <div className={`${styles.wrap} ${styles.currency}`}>
                          <p>Currency</p>
                          <Form.Group className={styles.selectWrap}>
                            <Form.Control
                              as="select"
                              defaultValue="GBP"
                              defaultChecked={true}
                              onChange={(e) => {
                                setBallotItem({
                                  ...ballotItem,
                                  currency: e.target.value,
                                });
                              }}
                            >
                              <option>GBP</option>
                              <option>EUR</option>
                              <option>USD</option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                      )}
                    </div>
                  ) : null}
                  <div className={styles.buttonWrap}>
                    {ballotItem && ballotStatus === "Open" ? (
                      <Button
                        name="Ballot"
                        onClick={handleEnterBallot}
                        disabled={!ballotItem || ballotStatus !== "Open"}
                        className={styles.btnPrimary}
                      >
                        Buy now{" "}(
                        {formatCurrency(ballotItem.currency || "gbp", 0).format(
                          ballotItem.currency === "EUR"
                            ? ballotItem.eur
                            : ballotItem.currency === "USD"
                              ? ballotItem.usd
                              : ballotItem.gbp,
                        )}
                        )
                      </Button>
                    ) : (
                      <>
                        <Button
                          name="Newsletter"
                          onClick={handleOpenNewsLetter}
                          className={styles.btnPrimary}
                        >
                          Get Notifications
                        </Button>
                      </>
                    )}
                    <Button
                      className={styles.btnLight}
                      onClick={() => setScroll("BallotInfo")}
                    >
                      Read more{" "}
                      <i
                        style={{ width: "30px" }}
                        className="fa fa-long-arrow-down fa-fw"
                        aria-hidden="true"
                      />
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Artist */}

        <div className={`${styles.whiteBG}`}>
          <Container className={styles.sectionWrap}>
            <Row className={styles.artistRow}>
              <Col xs={12} md={6} className={styles.contentWrap}>
                <div className={styles.inner}>
                  { ballotItem?.artistHeadline ? (
                    <h3>{ballotItem.artistHeadline}</h3>
                  ) : (
                    <h3>Designing an icon</h3>
                  )}
                  <div
                    className={styles.descriptionWrap}
                    dangerouslySetInnerHTML={ballotDescriptionMarkup(
                      ballotItem?.artistDetails,
                    )}
                  />
                </div>
              </Col>
              {/* <Col xs={12} md={6} className={styles.imgsWrap}>
                <div id="enjoyImgLeft" className={styles.artistImg}>
                  <img
                    src={String(getInsert5Image(ballotItem?.attachments))}
                    alt="caskshare marketing image"
                  />
                </div>
              </Col> */}
              <Col xs={12} md={6} className={styles.imgsWrap}>
                <div id="enjoyImgLeft" className={styles.artistImg}>
                  <img
                    src={String(getInsert5Image(ballotItem?.attachments))}
                    alt={`${ballotItem?.pageTitle}`}
                  />
                </div>
                <div id="enjoyImgRight" className={styles.bottleImg}>
                  <img
                    src={
                      ballotItem?.type === "Cask"
                        ? getLargeBottle(ballotItem?.cask?.attachments)
                        : getBallotBottleImage(ballotItem?.attachments)
                          ? getBallotBottleImage(ballotItem?.attachments)
                          : ballotItem?.product?.image
                    }
                    alt={`${ballotItem.pageTitle} bottle image`}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Collab  */}

        <div className={styles.beigeBG}>
          <Container className={styles.sectionWrap}>
            <Row className={styles.distilleryRow}>
              <Col xs={12} md={6} className={styles.imgsWrap}>
                <div id="enjoyImgLeft" className={styles.topImg}>
                  <img
                    src={String(getInsert3Image(ballotItem?.attachments))}
                    alt="caskshare marketing image"
                  />
                </div>
                <div id="enjoyImgRight" className={styles.bottomImg}>
                  <img
                    src={String(getInsert4Image(ballotItem?.attachments))}
                    alt="caskshare marketing image"
                  />
                </div>
              </Col>
              <Col xs={12} md={6} className={styles.contentWrap}>
                <div className={styles.inner}>
                  <h3>{ballotItem.collaborationTitle}</h3>

                  <div
                    className={styles.descriptionWrap}
                    dangerouslySetInnerHTML={ballotDescriptionMarkup(
                      ballotItem.collaborationDetails,
                    )}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* DETAIL SECTION */}
        <div className={styles.whiteBG}>
          <Container className={styles.ballotInfoCon}>
            <Row id="BallotInfo" className={styles.ballotInfo}>
              <Col xs={12} md={7} className={styles.contentCol}>
                {width < breakpoint ? (
                  <div className={styles.content}>
                    <div className={styles.distillery}>
                      <p>{ballotItem.subtitle}</p>
                    </div>
                    {/* <h3 className={styles.title}>{ballotItem?.title}</h3> */}
                    <div
                      className={styles.title}
                      dangerouslySetInnerHTML={ballotTitleMarkup(
                        ballotItem?.title,
                      )}
                    />

                    {ballotInfoData && (
                      <div className={styles.slider}>
                        <GeneralSliderMobile
                          data={ballotInfoData}
                          collectiveClass={true}
                          activeIndex={2}
                          title={false}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={styles.content}>
                    <div className={styles.distillery}>
                      <p>{ballotItem.subtitle}</p>
                    </div>
                    {/* <h3 className={styles.title}>{ballotItem?.title}</h3> */}
                    <div
                      className={styles.title}
                      dangerouslySetInnerHTML={ballotTitleMarkup(
                        ballotItem?.title,
                      )}
                    />
                    {ballotInfoData && (
                      <div className={styles.slider}>
                        <GeneralSlider
                          data={ballotInfoData}
                          collectiveClass={true}
                          ballotClass={true}
                          activeIndex={2}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Col>
              <Col xs={12} md={5} className={styles.imgCol}>
                <img
                  src={String(getInsert6Image(ballotItem?.attachments))}
                  alt="caskshare marketing image"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
      {showMarketModal && (
        <div className={styles.marketModal}>
          <div className={styles.inner}>
            <div className={styles.content}>
              <p>
                You are now entering our UK site to view this product. You can
                change your shopping region to go back to your region’s store.
              </p>
            </div>
            <div className={styles.buttons}>
              <Button
                onClick={handleMarketChange}
                disabled={!ballotMarket}
                className={styles.btnCoralOutline}
              >
                Go to Caskshare UK
              </Button>
              <Button
                onClick={() => history.goBack()}
                className={styles.btnBlackOutline}
              >
                Go Back
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivateSale;
