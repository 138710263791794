import React, { useEffect, useState } from "react"; 
import { useSelector } from "react-redux";
import { RootState } from "../../../state/rootReducer";
import { Cask } from "../../../graphql/Casks";

const ShippingDate = ({ cask }: { cask: Cask }) => {
  const shop = useSelector((state: RootState) => state.shop);

  const formatDate = (d: Date) =>
    d.toLocaleString("default", { month: "short" }) + " " + d.getFullYear();

  const formatShippingDate:any = () => {
    let shippingDate =
      Number(cask.bottlingYear) +
      Number(cask.bottlingMonth + shop.shippingMonthIncrement) / 100;
    let today =
      Number(new Date().getFullYear()) +
      Number(new Date().getMonth() + 1) / 100;

    if (Number(shippingDate) >= Number(today)) {
      return (
        <p>
          Est. shipping:{" "}
          <span>
            {formatDate(
              new Date(
                cask.bottlingYear,
                Number(cask.bottlingMonth - 1 + shop.shippingMonthIncrement),
                1,
              ),
            )}
          </span>
        </p>
      );
    } else if (Number(shippingDate) < Number(today)) {
      return (
        <p>
          <span>Shipping now</span>
        </p>
      );
    } else {
      return "";
    }
  };

  return formatShippingDate();
}

export default ShippingDate;
