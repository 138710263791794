import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import {
  increaseCheckoutQuantity,
  reduceCheckoutQuantity,
  deleteFromCheckout,
} from "../../state/slices/checkoutSlice";
import { formatCurrency } from "../../utils/currency";
import { Cask } from "../../graphql/Casks";
import { Product } from "../../graphql/Products";
import LoadingView from "../../components/app/LoadingView";

// import CaskImage from "../casks/CaskImage";
import CaskImage from "../casks/.v2/CaskImage";
import BottleImage from "../../caskshare-v3/components/casks/caskCard/BottleImage";
import ProductImage from "../products/ProductImage";
import QuantityPicker from "./QuantityPicker";
import { LineItem } from "../../graphql/LineItem";
import CheckoutStockIssues from "./CheckoutStockIssues";
import { RootState } from "../../state/rootReducer";

const CaskShareBasketItem = ({
  item,
  canEditQuantities,
  checkoutStockIssues,
  isOversold,
  giftedLineItem,
  setGiftedLineItem,
  lineItemId,
  setLineItemId,
  colAndVou,
}: {
  item: LineItem;
  canEditQuantities?: boolean;
  checkoutStockIssues: any;
  isOversold?: boolean;
  giftedLineItem?: boolean;
  setGiftedLineItem?: any;
  lineItemId?: string;
  setLineItemId?: any;
  colAndVou?: number | any;
}) => {
  const dateForPicker = (date: any) => {
    return moment(new Date(date)).format("YYYY-MM-DD");
  };

  const elideStr = (str: string, len: number) =>
    str != null && str.length > len ? str.slice(0, len) + "..." : str;

  const breakpoint = 992;
  const [width, setWidth] = useState(window.innerWidth);
  const [publicPrice, setPublicPrice] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [priceTax, setPriceTax] = useState(0);
  const [isMember, setIsMember] = useState(false);
  const [isMemberData, setIsMemberData] = useState({});
  const [hasDiscounts, setHasDiscounts] = useState({});
  const [bottleImg, setBottleImg] = useState(
    require("../../assets/home-page/.v3/db-lg.png"),
  );
  const [bottleImgPlc, setBottleImgPlc] = useState(
    require("../../assets/home-page/.v3/db-sm.png"),
  );
  const [initialGiftData] = useState({
    giftCard: false,
    recipientEmail: "",
    recipientName: "",
    giftDate: dateForPicker(Date.now()),
    giftNote: "",
  });

  const loggedInUser = useSelector((state: RootState) => state.user);
  const shop = useSelector((state: RootState) => state.shop);
  const collectiveActive = useSelector(
    (state: RootState) => state.user.collectiveActive,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const onRecommendedCask = (c: Cask) => {
    history.push(`/caskshares/${c?.defaultVariant?.slug}`);
  };

  const onRecommendedProduct = async (p: Product) => {
    const productId = p.slug;
    history.push(`/products/${productId}`);
  };

  useEffect(() => {
    const attachments = item?.attachments || item?.cask?.attachments;
    if (attachments) {
      const large = attachments.find(({ role }: any) => role === "large");
      large && setBottleImg(large?.url);
      const small = attachments.find(({ role }: any) => role === "small");
      small && setBottleImgPlc(small?.url);
    }
    return () => {
      setBottleImg(require("../../assets/home-page/.v3/db-lg.png"));
      setBottleImgPlc(require("../../assets/home-page/.v3/db-sm.png"));
    };
  }, [item?.attachments, item?.cask?.attachments]);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    setPublicPrice(item.publicTotalPrice);
    setCurrentPrice(item.priceTotal);
    setPriceTax(item.priceTax);

    return () => {
      setPublicPrice(0);
      setCurrentPrice(0);
    };
  }, [item]);

  useEffect(() => {
    if (loggedInUser.email && collectiveActive) {
      setIsMember(true);
    }
    return () => {
      setIsMember(false);
    };
  }, [collectiveActive]);

  const handleRemoveGiftData = (product: any) => {
    dispatch(reduceCheckoutQuantity(product, initialGiftData));
  };

  // if (!publicPrice || !currentPrice) {
  //   return <LoadingView />;
  // }

  const isShippingFee = item.productType === "bottled_caskshare";

  const bundleChecker =
    (item.bundleId === null && item.isABundle === false) ||
    item.isABundle === true;

  const giftingLink = () => {
    if (item.productType === "gift") {
      return item.title.toLowerCase().includes("collective")
        ? "/gifting#vouchers"
        : item.title.toLowerCase().includes("voucher")
        ? "/gifting#membership"
        : null;
    }

    return null;
  };

  return (
    <li
      key={item.id}
      className={`basket-item ${item.bundleChild && "basket-item-child"}`}
    >
      <Row className="d-flex align-items-center">
        <Col xs={12} lg={12}>
          <Row className="d-flex align-items-center mt-2">
            {canEditQuantities ? (
              <>
                <Col
                  lg={{
                    span: item.bundleChild ? 2 : 3,
                    offset: item.bundleChild ? 1 : 0,
                  }}
                  md={{
                    // span: item.bundleChild ? 5 : isShippingFee ? 4 : 6,
                    span: item.bundleChild ? 5 : 6,
                    offset: item.bundleChild ? 1 : 0,
                  }}
                  xs={{
                    span: item.bundleChild ? 6 : 12,
                    offset: item.bundleChild ? 0 : 0,
                  }}
                  style={{ position: "relative" }}
                  className="bottle-img-wrap mb-2"
                >
                  {item.type === "cask" ? (
                    // <CaskImage
                    //   // @ts-ignore
                    //   cask={item.cask}
                    //   newReserved={
                    //     item.cask.totalReservedBottles + item.quantity
                    //   }
                    //   onClick={() => onRecommendedCask(item.cask)}
                    // />
                    <BottleImage
                      placeholderSrc={bottleImgPlc}
                      src={bottleImg}
                    />
                  ) : (
                    <ProductImage product={item.product} />
                  )}

                  {!canEditQuantities ? (
                    <div className="number-of-shares-blob">
                      {item.productType === "gift" ? 1 : item.quantity}
                    </div>
                  ) : null}
                </Col>
                <Col
                  lg={isShippingFee ? 5 : 3}
                  md={6}
                  xs={{
                    span: item.bundleChild ? 6 : isShippingFee ? 10 : 12,
                    offset: item.bundleChild ? 0 : 0,
                  }}
                  className="title-area"
                >
                  <h5>
                    {item.type === "cask"
                      ? elideStr(
                          item.cask.distillery.name + " " + item.cask.title,
                          25,
                        )
                      : elideStr(item.product.title, 25)}
                  </h5>
                  <span>
                    {item.type === "cask"
                      ? elideStr(
                          item.cask.distillery.name + " " + "Distillery",
                          25,
                        )
                      : elideStr(item.product.vendor, 25)}
                  </span>
                  {item.bundleChild && width < breakpoint ? (
                    <p className="child-description">
                      {item.type === "cask"
                        ? elideStr(
                            item.cask.shortDescription,
                            width < 600 ? 50 : width < 768 ? 180 : 120,
                          )
                        : elideStr(
                            item.product.description,
                            width < 600 ? 50 : width < 768 ? 180 : 120,
                          )}
                    </p>
                  ) : null}
                  {bundleChecker &&
                  item.productType !== "gift" &&
                  !isShippingFee ? (
                    <div className="gifting-checkbox">
                      {item.gift === false ? (
                        <>
                          <input
                            type="checkbox"
                            id={`gifting-${item.id}`}
                            className={""}
                            checked={giftedLineItem && lineItemId === item.id}
                            onChange={() => {
                              setLineItemId(item.id);
                              setGiftedLineItem(!giftedLineItem);
                            }}
                          />
                          <label className={""} htmlFor={`gifting-${item.id}`}>
                            Is it a gift?
                          </label>
                        </>
                      ) : (
                        <p
                          onClick={() =>
                            handleRemoveGiftData(
                              item.type === "cask" ? item.cask : item.product,
                            )
                          }
                        >
                          remove the gift
                        </p>
                      )}
                    </div>
                  ) : null}
                </Col>
                {item.bundleChild && width > breakpoint ? (
                  <Col xs={6} className="child-description">
                    {item.type === "cask"
                      ? elideStr(item.cask.shortDescription, 90)
                      : elideStr(item.product.description, 90)}
                  </Col>
                ) : null}
                {item.productType === "gift" &&
                giftingLink() &&
                bundleChecker &&
                colAndVou < 2 ? (
                  <>
                    <Col xs={0} lg={4}></Col>
                    <Col xs={4} lg={6} className="mt-4 basketItem-member-price">
                      <Link className="member-price" to={giftingLink() ?? ""}>
                        <p style={{ color: "#e76852", whiteSpace: "nowrap" }}>
                          {giftingLink()?.includes("membership")
                            ? "Add a membership?"
                            : "Add a voucher?"}
                        </p>
                      </Link>
                    </Col>
                  </>
                ) : null}
                {canEditQuantities && bundleChecker ? (
                  <Col
                    lg={3}
                    md={isShippingFee ? 10 : giftingLink() ? 2 : 6}
                    xs={isShippingFee ? 10 : giftingLink() ? 2 : 6}
                    className="mt-4"
                  >
                    {item.type === "cask" ? (
                      <QuantityPicker
                        maxBottles={item.cask.totalBottles}
                        currentBottles={item.cask.totalReservedBottles}
                        isShippingFee={isShippingFee}
                        quantity={item.quantity}
                        onAddition={() => {
                          dispatch(
                            increaseCheckoutQuantity({
                              product:
                                item.type === "product"
                                  ? item.product
                                  : item.cask,
                            }),
                          );
                        }}
                        onSubtraction={() => {
                          dispatch(
                            reduceCheckoutQuantity(
                              item.type === "product"
                                ? item.product
                                : item.cask,
                            ),
                          );
                        }}
                      />
                    ) : (
                      item.productType !== "gift" && (
                        <QuantityPicker
                          maxBottles={item.product.quantity}
                          currentBottles={0}
                          quantity={item.quantity}
                          limitedStock={item.product.onePerCustomer}
                          isShippingFee={isShippingFee}
                          onAddition={() => {
                            dispatch(
                              increaseCheckoutQuantity({
                                product:
                                  item.type === "product"
                                    ? item.product
                                    : item.cask,
                              }),
                            );
                          }}
                          onSubtraction={() => {
                            dispatch(
                              reduceCheckoutQuantity(
                                item.type === "product"
                                  ? item.product
                                  : item.cask,
                              ),
                            );
                          }}
                        />
                      )
                    )}
                  </Col>
                ) : null}
                {bundleChecker && !isShippingFee ? (
                  <Col
                    lg={2}
                    md={4}
                    xs={4}
                    className="mt-4 basketItem-member-price"
                  >
                    {currentPrice !== publicPrice || item.promoCode ? (
                      <div className="member-price">
                        <div
                          style={{
                            textDecoration: "line-through",
                          }}
                        >
                          <p>
                            {formatCurrency(shop.currency, 2).format(
                              publicPrice,
                            )}
                          </p>
                        </div>
                        <div>
                          <p>
                            {formatCurrency(shop.currency, 2).format(
                              currentPrice,
                            )}
                          </p>
                        </div>
                        {item.promoCode && (
                          <div>
                            <p style={{ marginTop: ".5rem", color: "#e76852" }}>
                              {item.promoCode}
                            </p>
                          </div>
                        )}
                      </div>
                    ) : currentPrice > 0 ? (
                      <div>
                        <p style={{ margin: "0" }}>
                          {formatCurrency(shop.currency, 2).format(
                            currentPrice,
                          )}
                        </p>
                      </div>
                    ) : null}
                  </Col>
                ) : null}
                {canEditQuantities && bundleChecker ? (
                  <Col
                    lg={1}
                    // md={isShippingFee ? 1 : 2}
                    md={2}
                    xs={2}
                    style={{ marginLeft: "auto" }}
                    className="mt-4"
                  >
                    <div style={{ paddingRight: "15px" }}>
                      <i
                        onClick={() => dispatch(deleteFromCheckout(item))}
                        className="delete-from-checkout-button fa fa-trash fa-fw"
                        aria-hidden="true"
                      />
                    </div>
                  </Col>
                ) : null}
                {isOversold || (checkoutStockIssues?.length && !isShippingFee)
                  ? checkoutStockIssues.map(
                      (checkoutStockIssue: any) =>
                        checkoutStockIssue.id ===
                          item.product.defaultVariant.id && (
                          <CheckoutStockIssues
                            key={checkoutStockIssue.id}
                            checkoutStockIssue={checkoutStockIssue}
                          />
                        ),
                    )
                  : null}
                {isShippingFee && (
                  <Col xs="12" className="stock-issues">
                    <p>
                      Full shipping fee will be calculated once your delivery
                      address is confirmed.
                    </p>
                  </Col>
                )}
              </>
            ) : (
              <>
                <Col
                  lg={{
                    span: item.bundleChild ? 4 : 5,
                    offset: item.bundleChild ? 1 : 0,
                  }}
                  // xs={12}
                  xs={{
                    span: item.bundleChild ? 6 : 12,
                    offset: item.bundleChild ? 0 : 0,
                  }}
                  style={{ position: "relative", display: "flex" }}
                  className="bottle-img-wrap"
                >
                  {item.type === "cask" ? (
                    // <CaskImage
                    //   // @ts-ignore
                    //   cask={item.cask}
                    //   newReserved={
                    //     item.cask.totalReservedBottles + item.quantity
                    //   }
                    // />
                    <BottleImage
                      placeholderSrc={bottleImgPlc}
                      src={bottleImg}
                    />
                  ) : (
                    <ProductImage product={item.product} />
                  )}

                  {!canEditQuantities && !item.bundleChild ? (
                    <div className="number-of-shares-blob">
                      {checkoutStockIssues?.length
                        ? checkoutStockIssues.map((checkoutStockIssue: any) =>
                            checkoutStockIssue.id ===
                            item.product.defaultVariant.id ? (
                              <i
                                key={checkoutStockIssue.id}
                                className="fa fa-times"
                              ></i>
                            ) : (
                              `${
                                item.productType === "gift" ? 1 : item.quantity
                              }`
                            ),
                          )
                        : `${item.productType === "gift" ? 1 : item.quantity}`}
                    </div>
                  ) : null}
                </Col>

                <Col lg={4} md={8} sm={12} className="title-area">
                  <h5>
                    {item.type === "cask"
                      ? elideStr(item.cask.title, 25)
                      : elideStr(item.product.title, 25)}
                  </h5>
                  <span>
                    {item.type === "cask"
                      ? item.cask.distillery.name + " " + "Distillery"
                      : item.product.vendor}
                  </span>
                </Col>

                {!isShippingFee && currentPrice > 0 && (
                  <Col
                    lg={3}
                    md={4}
                    style={{
                      fontSize: "18px",
                      textDecoration: item.bundleChild
                        ? "line-through"
                        : "none",
                    }}
                    className="d-flex mt-3"
                  >
                    {formatCurrency(shop.currency, 2).format(currentPrice)}
                  </Col>
                )}
              </>
            )}
          </Row>
        </Col>
      </Row>
      {/* <Row className="collective-upsell-row">
        <Col>
          {isMember ? (
            <div className="member-banner">
              you saved {formatCurrency().format(publicPrice - currentPrice)}{" "}
              with the Collective membership
            </div>
          ) : (
            <div className="nonMember-banner">
              <p>
                you could save{" "}
                {formatCurrency().format(publicPrice - currentPrice)} with the
                Collective membership
              </p>
            </div>
          )}
        </Col>
      </Row> */}
    </li>
  );
};

export default CaskShareBasketItem;
